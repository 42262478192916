import React from 'react'; // eslint-disable-line no-unused-vars
import Layout from '../components/Layout';
import { List, Paragraph, Title } from '../components/Texts';

const References = () => (
  <Layout title="Références">
    <Title>
      Références
    </Title>

    <Paragraph>Voici quelques structures ayant fait confiance à Mak Corp&nbsp;:</Paragraph>

    <Title level={2}>Recherche</Title>

    <List>
      <List item>CNRS (Délégation Bretagne et Pays de la Loire, Occitanie, Paris B, Paris-Centre, IPBS…)</List>
      <List item>CIS - Conseil international des sciences </List>
      <List item>CREA Mont-Blanc - Centre de Recherches sur les Ecosystèmes d’Altitude</List>
      <List item>INRAE - Institut National de Recherche pour l’Agriculture, l’alimentation et l’Environnement</List>
      <List item>IRSN – Institut Royal des Sciences Naturelles</List>
      <List item>Laboratoire de l’École des Mines de Nantes</List>
      <List item>Laboratoire Écologie fonctionnelle et Environnement -  UMR 5245</List>
      <List item>Laboratoire Environnement Ville société - UMR 5600</List>
      <List item>LEGOS - Laboratoire d'Études en Géophysique et Océanographie Spatiales - UMR5566</List>
      <List item>LIUPPA – Laboratoire d'Informatique de l'Université de Pau et des Pays de l'Adour</List>
      <List item>Museum National d'Histoire Naturelle</List>
      <List item>Toulouse Tech Transfer</List>
    </List>

    <Title level={2} sx={{ mt: 5 }}>Structures publiques</Title>

    <List>
      <List item>ADEME</List>
      <List item>Agence de Développement Economique d'Occitanie – AD'OCC</List>
      <List item>Agence Régionale du Tourisme du Grand-Est</List>
      <List item>Angers Loire Développement</List>
      <List item>Air Pays de Loire</List>
      <List item>BRGM</List>
      <List item>CAUE Dordogne</List>
      <List item>CCI Montauban Tarn-et-Garonne</List>
      <List item>CCI Nice Côte d'Azur</List>
      <List item>CCI Occitanie</List>
      <List item>Conservatoire du Littoral</List>
      <List item>DGAC - Direction de la Technique et de l'Innovation</List>
      <List item>DSAC-EC Direction de la Sécurité de l'Aviation Civile</List>
      <List item>EPAMA – EPTB Meuse</List>
      <List item>IRSN - Institut de Radioprotection et de Sûreté Nucléaire </List>
      <List item>Nantes Saint-Nazaire Développement</List>
      <List item>Office de l'eau de Guadeloupe</List>
      <List item>Office de l'eau de la Martinique </List>
      <List item>Office National de la Chasse et de la Faune Sauvage</List>
      <List item>ORS Pays de la Loire</List>
      <List item>Programme des Nations Unies pour l'Environnement – PNUE</List>
      <List item>RAC-REMPEITC - Regional Marine Pollution Emergency, Information and Training Centre</List>
      <List item>REMPEC - Regional Marine Pollution Emergency Response Centre for the Mediterranean Sea</List>
      <List item>Loire Atlantique Développement</List>
    </List>

  </Layout>
);

export default References;
